import React from 'react'
import Seo from '../../components/Seo'
import { useLangContext } from '../../context/lang.context'
// import FormService from "./FormService/FormService"
import IntroSection from './IntroSection/IntroSection'
import { seo } from './seo'
import ServicesAll from './ServicesAll/ServicesAll'
// import BannerSection from "../Index/BannerSection/BannerSection"
import WhereSection from '../Index/WhereSection/WhereSection'
import OfferSection from '../Offer/OfferSection/OfferSection'

const Services = () => {
  const { lang } = useLangContext()

  return (
    <>
      <Seo
        title={seo.title[lang]}
        description={seo.description[lang]}
        lang={lang}
      />
      <IntroSection />
      <ServicesAll />
      <OfferSection />
      <WhereSection />
      {/* <FormService page='services' pages={['service']} /> */}
      {/* <BannerSection largeWidth /> */}
    </>
  )
}

export default Services
