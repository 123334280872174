export const seo = {
  title: {
    pl: 'Usługi TSL | CARGONITE',
    en: 'Services',
  },
  description: {
    pl: 'W ramach naszych usług przygotowaliśmy dla naszych klientów usługę Customer Care, która pozwala na stały kontakt z działem obsługi. Oferujemy również założenie linki celnej do zamknięcia i zaplombowania skrzyni ładunkowej.',
    en: 'We are offering our clients a Customer Care service, which allows for constant contact with the service department. We also offer securing the cargo with a customs-approved fastening rope to close and seal the cargo box.',
  },
}
