import React from 'react'
import Layout from '../components/Layout'
import Services from '../page/Services/Services'

const ServicesPage = () => (
  <Layout>
    <Services />
  </Layout>
)

export default ServicesPage
